$purple: #4d2a49;
$gold: #e7bf36;

html[data-bs-theme="light"] {
  & .text-theme {
    color: $purple;
  }
}

html[data-bs-theme="dark"] {
  & .text-theme {
    color: $gold;
  }
}
